<template>
  <ion-page>
    <Header title="Detail Pesanan" />
    <ion-content :fullscreen="true">
      <div class="container" v-if="item">
        <div class="card-coupon">
          <div class="left-image">
            <ion-avatar>
              <img :src="item.product.image" :alt="item.product.name">
            </ion-avatar>
          </div>
          <div class="item-description">
            <div class="t">{{ item.product.name }}</div>
            {{ $filters.numFormat(item.amount) }} poin
          </div>
        </div>
        <div class="a1s text-sm mt-2">No Invoice</div>
        <div class="code bold mb-2">
          {{ item.invoice }}
        </div>
        <div class="divinder"></div>
        <div class="detail">
          <div class="x-row">
            <div class="label">Nama</div>
            <div class="value">{{ item.shipping_name }}</div>
          </div>
          <div class="x-row">
            <div class="label">No. Handphone</div>
            <div class="value">{{ item.shipping_phone }}</div>
          </div>
          <div class="x-row">
            <div class="label">Alamat</div>
            <div class="value">
              <div class="x-line">
                {{ item.shipping_address.address }}
              </div>
              <div class="x-line">
                {{ item.shipping_address.city }}
              </div>
              <div class="x-line">
                {{ item.shipping_address.postal_code }}
              </div>
            </div>
          </div>
          <div class="x-row">
            <div class="label">Status</div>
            <div class="value">
              {{ status[item.status] }}
            </div>
            <div class="value">
              {{ item.note }}
            </div>
          </div>
          <div class="x-row" v-if="item.resi_number">
            <div class="label">No. Resi</div>
            <div class="valu">{{ item.provider }} / {{ item.resi_number }} </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonAvatar } from '@ionic/vue'
import { defineComponent } from 'vue'
import Header from '@/components/headerPage'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonAvatar,
    Header
  },
  data () {
    return {
      item: null,
      status: ["Di Batalkan", "Sedang Diproses", "Di Tunda", "Berhasil"],
    }
  },
  created() {
    this.detail()
  },
  watch: {
    '$route': 'detail'
  },
  methods: {
    async detail() {
      let id = this.$route.params.id
      if(id)
        this.item = await this.$api.get(`/api/store/transaction/${id}`).then(res => res.data)
    },
  }
})
</script>

<style lang="scss">
.x-row {
  margin-bottom: .6rem;
  .label {
    font-size: 14px;
    color: #545454;
  }
}
</style>
